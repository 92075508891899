<template>
  <nav>
    <router-link to="/fmk3">fkm3设计器</router-link> |
    <router-link to="/fmk3View">fkm3设计器</router-link> |
  </nav>
  <router-view/>
</template>

<style lang="scss">
html,body,#app{
  width: 100%;
  height: 100%;
}

nav {
  padding: 0;
  text-align: center;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
