import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import FormMakingV3 from "@/components/fmk3/dist/form-making-v3.es.js";

import "@/components/fmk3/dist/index.css";
import "./assets/style/index.scss";

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(FormMakingV3, {
    locale: "zh-cn",
  })
  .mount("#app");
